<!-- 合作银行列表 -->
<template>
  <div class="bankList">
    <div class="topFixed" ref="topFixed" :style="`top:${barToTop}px`">
      <Search placeholder="搜索资方" @input="input" />
      <ImgSwiper
        class="img-swiper"
        v-if="focusBanner.length"
        :bannerList="focusBanner"
      />
      <CircleTab :list="tabList" :active="activeKey" @tabChange="tabChange" />
    </div>
    <!-- 索引列表 -->
    <van-index-bar
      :index-list="indexList"
      :sticky-offset-top="indexToTop"
      @select="indexSelect"
      ref="indexBar"
    >
      <template v-for="(ele, key) in bankList">
        <van-index-anchor :key="key" :index="key" />
        <van-cell v-for="item in ele" :key="item.id" @click="nextPage(item)">
          <template #title>
            <img class="funderLogo" :src="item.funderLogo" />
            <p>{{ item.funderName }}</p>
          </template>
        </van-cell>
      </template>
    </van-index-bar>
    <!-- 淡入字母显示 -->
    <LetterFadeIn ref="LetterFadeIn" :activeLetter="activeLetter" />
  </div>
</template>

<script>
import Search from "@/components/Search";
import CircleTab from "@/components/CircleTab.vue";
import LetterFadeIn from "@/components/LetterFadeIn.vue";
import { funderList, getBannerList } from "@/api/api_home";
import { mapState } from "vuex";
import IndexScroll from "@/utils/indexScroll";
import ImgSwiper from "@/components/Swiper.vue";
export default {
  name: "bankList",
  data() {
    return {
      barToTop: 0,
      indexToTop: 0, // 索引列表吸顶距顶部距离
      activeKey: "newCar",
      tabList: [
        { name: "新车", code: "newCar" },
        { name: "二手车", code: "usedCar" }
      ],
      originalBanks: [],
      bankList: [],
      indexList: [],
      activeLetter: "A",
      focusBanner: []
    };
  },
  computed: {
    ...mapState(["isShowDownLoad", "position"])
  },
  watch: {
    isShowDownLoad() {
      this.calcHeight();
    }
  },
  created() {
    this.getImgList();
    this.getbankList();
  },
  methods: {
    // 计算固定定位元素距顶部高度
    calcHeight() {
      this.barToTop = this.$tools.calcTopHeight();
      const topHeight = this.$refs.topFixed.offsetHeight;
      this.indexToTop = this.barToTop + topHeight;
      const d = document.getElementsByClassName("van-index-bar")[0];
      d.style.marginTop = topHeight / 100 + "rem";
    },
    // 获取广告
    getImgList() {
      getBannerList({
        cityCode: this.position.cityId,
        adPage: 5
      }).then(res => {
        if (res.code === 0) {
          const info = res.data.info;
          this.focusBanner = info.focusBanner;
          this.$nextTick(() => {
            this.calcHeight();
          });
        }
      });
    },
    // 搜索
    input(bank) {
      const newList = this.originalBanks.filter(item => {
        return item.funderName.includes(bank);
      });
      this.handleList(newList);
    },
    tabChange(code) {
      this.activeKey = code;
      this.getbankList();
    },
    // 选择索引
    indexSelect(item) {
      this.activeLetter = item;
      this.$refs.LetterFadeIn.letterClick();
      IndexScroll(item, this);
    },
    // 获取资方列表
    getbankList() {
      funderList({ bizType: this.activeKey }).then(res => {
        if (res.code === 0) {
          const result = res.data.info;
          this.originalBanks = result;
          this.handleList(result);
        }
      });
    },
    // 资方数据按字母分类
    handleList(result) {
      const ls = this.$tools.handleArrayClassify(result, "funderFirstLetter");
      this.bankList = ls.arr;
      this.indexList = ls.indexList;
      // 切换页签返回顶部
      this.$refs.indexBar.scrollTo(this.indexList[0]);
    },
    nextPage(item) {
      this.$router.push({ name: "BankDetail", query: { id: item.id } });
    }
  },
  components: { Search, CircleTab, LetterFadeIn, ImgSwiper }
};
</script>
<style lang="scss" scoped>
.bankList {
  background-image: linear-gradient(180deg, #fff, rgb(240, 241, 243));
  padding-bottom: 0.44rem;
  height: 100%;
  .topFixed {
    position: fixed;
    top: 0.44rem;
    left: 0;
    right: 0;
    z-index: 50;
    background-image: linear-gradient(180deg, #fff, rgb(251, 253, 252));
    .Search {
      margin: 0.12rem 0.18rem;
    }
    .CircleTab {
      margin: 0 0.18rem 0.12rem;
    }
  }

  .img-swiper {
    margin-bottom: 0.16rem;
  }
  .van-cell {
    padding: 0.08rem 0.18rem;
  }
  .van-index-bar {
    margin-top: 0.96rem;
  }
  .van-cell__title {
    display: flex;
    align-items: center;
    .funderLogo {
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.1rem;
    }
  }
}
</style>
